import React from 'react';
import '../main.css';
import { AuthorPile, InfoPage } from './HelperComponents';
import Details from './Details';
import * as dataObjects from './dataObjects.js';

export function Cocoa() {
  return (
    <InfoPage helmetExt="Cocoa">

      <h2>{dataObjects.CocoaObj.title}</h2>
      <AuthorPile authors={dataObjects.CocoaObj.authors}/>
      <p className="venue-page">{dataObjects.CocoaObj.venue}</p>

      <h3>Abstract</h3>
      <p>We present Cocoa, a system that implements a novel interaction design pattern—interactive plans—for users to collaborate with an AI agent on complex, multi-step tasks in a document editor. Cocoa harmonizes human and AI efforts and enables flexible delegation of agency through two actions: Co-planning (where users collaboratively compose a plan of action with the agent) and Co-execution (where users collaboratively execute plan steps with the agent). Using scientific research as a sample domain, we motivate the design of Cocoa through a formative study with 9 researchers while also drawing inspiration from the design of computational notebooks. We evaluate Cocoa through a user study with 16 researchers and find that when compared to a strong chat baseline, Cocoa improved agent steerability without sacrificing ease of use. A deeper investigation of the general utility of both systems uncovered insights into usage contexts where interactive plans may be more appropriate than chat, and vice versa. Our work surfaces numerous practical implications and paves new paths for interactive interfaces that foster more effective collaboration between humans and agentic AI systems.</p>

      <h3>Details</h3>
      <Details {...dataObjects.CocoaObj}/>

    </InfoPage>
  );
}
