import React, { useEffect } from 'react';
import Showcase from './Showcase.js';

function Highlights() {
  useEffect(() => {
    sessionStorage.setItem("onDetailsPage", 0);
  }, []);

  return (
    <div className="showcase-wrapper">
      <Showcase
        thumbnail={ require('./assets/img/cocoa-cover.png').default }
        title="Cocoa"
        subtitle="Co-Planning and Co-Execution with AI Agents"
        venue="arXiv 2025"
        urlext="cocoa"
         paper="https://arxiv.org/pdf/2412.10999.pdf"
        doi="https://arxiv.org/abs/2412.10999"
        demo="https://youtu.be/FMx5k4geWxs"
      />
      <Showcase
        thumbnail={ require('./assets/img/canvil-cover.png').default }
        title="Canvil"
        subtitle="Designerly Adaptation for LLM-Powered User Experiences"
        venue="CHI 2025"
        urlext="canvil"
        paper="https://arxiv.org/pdf/2401.09051.pdf"
        doi="https://arxiv.org/abs/2401.09051"
        demo="https://www.figma.com/community/widget/1277396720888327660"
      />
       <Showcase
        thumbnail={ require('./assets/img/policy-prototyping-cover.png').default }
        title="Policy Prototyping for LLMs"
        subtitle="Pluralistic Alignment via Interactive and Collaborative Policymaking"
        venue="arXiv 2024"
        urlext="policy-prototyping-llms"
        paper="https://arxiv.org/pdf/2409.08622"
        doi="https://arxiv.org/abs/2409.08622"
      />
      <Showcase
        thumbnail={ require('./assets/img/ai-legal-advice-cover.png').default }
        title="Responsible AI-Generated Legal Advice"
        subtitle="Engaging Legal Experts towards Responsible LLM Policies for Legal Advice"
        venue="FAccT 2024"
        urlext="ai-am-not-a-lawyer"
        paper="https://arxiv.org/pdf/2402.01864"
        doi="https://dl.acm.org/doi/abs/10.1145/3630106.3659048"
      />
      
      <Showcase
        thumbnail={ require('./assets/img/teachable-feeds-cover.png').default }
        title="Teachable Social Media Feeds"
        subtitle="Mapping the Design Space of Teachable Social Media Feed Experiences"
        venue="CHI 2024"
        urlext="teachable-feed-experiences"
        paper="https://arxiv.org/pdf/2401.14000.pdf"
        doi="https://dl.acm.org/doi/10.1145/3613904.3642120"
        slides={require("./assets/files/teachable-feeds-slides.pdf").default}
      />
      <Showcase
        thumbnail={ require('./assets/img/case-repos-cover.png').default }
        title="Democratic Inputs to AI"
        subtitle="Case-Based Reasoning for AI Alignment"
        venue="MP2 @ NeurIPS 2023"
        urlext="case-repositories"
        paper="https://arxiv.org/pdf/2311.10934.pdf"
        demo="https://social.cs.washington.edu/case-law-ai-policy/"
        poster={require("./assets/files/mp2_case_law_poster.pdf").default}
      />
      
     

      {/*<img src={ require('./assets/img/construction.gif').default } style={{maxWidth: 500}}/>*/}
    </div>
  );
}

export default Highlights;
